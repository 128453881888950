const RETURN_URL_KEY = 'auth_return_url';

export const storeReturnUrl = (url: string): void => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(RETURN_URL_KEY, url);
  }
};

export const getAndClearReturnUrl = (): string | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  const returnUrl = sessionStorage.getItem(RETURN_URL_KEY);
  sessionStorage.removeItem(RETURN_URL_KEY);
  return returnUrl;
};
